<template>
  <router-view v-slot="slotProps">
     <component :is="slotProps.Component"></component>
  </router-view>
</template>

<script>
export default {
  setup() {
  },
};
</script>
